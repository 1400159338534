/* global window, HTMLElement, document */

class SimplePieChart extends HTMLElement {

	constructor() {
		const parent = super();
		return parent;
	}

	connectedCallback() {
		
		this._drawPie();
		this._setupResizeListener();

	}


	/**
	* Returns dimensions of current element. 
	* @returns {Object}		Object with properties height and width
	*/
	_getDimensions() {
		return {
			height		: this.clientHeight
			, width		: this.clientWidth
		};
	}



	/**
	* When window resized, update pie
	*/
	_setupResizeListener() {

		window.addEventListener('resize', () => {

			if (this._resizeTimeout) clearTimeout(this._resizeTimeout);

			this._resizeTimeout = setTimeout(() => {
				this._drawPie();
			}, 200);

		});

		// Invisible tabs have display:none, therefore no height/width. Make sure
		// we update the pies when tabs become visible. This is a not-so-nice
		// workaround (we could also remove the invisible tab from the DOM)
		window.addEventListener('tab-switch', () => {
			console.log('SimplePieChart: Tabs switched');
			setTimeout(this._drawPie.bind(this), 20);
		});

	}


	/**
	* @param {Number} radAngle		Angle in radants
	*/
	_drawPie() {

		const angle = this.getAttribute('progress');
		let radAngle = parseFloat(angle) * Math.PI * 2;
		// 540° -> 180°
		radAngle = radAngle % (Math.PI * 2);
		// 360° becomes 0° -> leave it at 360°
		if (parseInt(angle) === 1) radAngle = Math.PI * 2;
		console.log('SimplePieChart: Draw pie with angle %o, percent is %o', radAngle, angle);

		const dimensions = this._getDimensions();

		const svg = document.createElementNS('http://www.w3.org/2000/svg','svg');
		svg.setAttribute('height', dimensions.height);
		svg.setAttribute('width', dimensions.width);

		// Circle around the stats
		const cirlceStrokeWidth = 2;
		const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
		// If height is 0, radius might be negative – that's invalid, indeed.
		circle.setAttribute('r', Math.max(0, dimensions.height / 2 - cirlceStrokeWidth));
		circle.setAttribute('cx', dimensions.width / 2);
		circle.setAttribute('cy', dimensions.height / 2);
		circle.setAttribute('fill', 'none');
		circle.setAttribute('stroke', 'white');
		circle.setAttribute('stroke-width', 2);
		svg.appendChild(circle);

		// Real Pie
		const pie = this._createArcDefinition(radAngle, dimensions, cirlceStrokeWidth);
		svg.appendChild(pie);

		// Remove children
		while(this.children.length) {
			this.removeChild(this.children[0]);
		}

		this.appendChild(svg);

	}


	/**
	* Create (partial) circle: Must consist of one or more arcs (one for each half)
	*/
	_createArcDefinition(angle, dimensions, stroke) {

		console.log('SimplePieChart: Draw arc with angle %o, dimensions %o, stroke %o', angle, dimensions, stroke);

		const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
			,  d = []
			, xRadius = dimensions.width / 2 - stroke
			, yRadius = dimensions.height / 2 - stroke;

		const start = {
				x		: dimensions.width / 2
				, y		: stroke
			}
			, end	= {
				x		: Math.sin(angle) * xRadius + dimensions.width / 2
				, y		: Math.cos(angle) * -1 * yRadius + dimensions.height / 2
			};

		// If start and end are too close together (360°), no circle will be drawn at all
		// If angle i 0, don't draw anything at all: start and end should be the same.
		if (Math.abs(start.x - end.x) < 1 && angle !== 0) end.x = start.x - 1;

		// Right side: Move to top center
		if (angle !== Math.PI * 2) {
			d.push(`M ${ dimensions.width / 2 } ${ dimensions.height / 2 }`);
		}
		// If angle is 360°, we need a full circle. Don't start from the center so that
		// we can correctly close the circle.
		else {
			d.push(`M ${ start.x } ${ start.y }`);
		}
		// Move to 12 o'clock
		d.push(`L ${ start.x } ${ start.y } `);
		// Draw arc; if angle is 360° we modify start/end (see above)
		d.push(` A ${ xRadius } ${ yRadius } 0 ${ angle > Math.PI ? 1 : 0 } 1 ${ end.x } ${ end.y }`);
		// Close path
		d.push('Z');

		path.setAttribute('d', d.join(' '));
		path.setAttribute('fill', 'white');
		return path;

	}


}

window.customElements.define('simple-pie-chart', SimplePieChart);

